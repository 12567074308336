$(document).ready(function() {
    /* MENU */
    // Mob MainMenu
    $('.close-mob-menu').click(function () {
        $('#mob-menu').removeClass('active-mob-menu');
        $('body').removeClass('overflow-h');
        $('.close-mob-menu').hide();
    });

    $('#button-menu').click(function () {
          $('#mob-menu').toggleClass('active-mob-menu');
          $('body').toggleClass('overflow-h');
          $('.close-mob-menu').show();
    });

    // Menu Responsive
    function rMenu(){

        $('ul.all-dropdown-menu li.item').appendTo('ul.navbar-nav');
        var items = $('ul.navbar-nav li.item');
        var max_width = $('ul.navbar-nav').width() - $('ul.navbar-nav li.all-category').outerWidth();
        var width = 0;
        var hide_from = 0;

        //items.css({'width':'auto'});

        items.each(function(index){
            if (width + $(this).outerWidth() > max_width)
            {
                return false;
            }
            else
            {
                hide_from = index;
                width += $(this).outerWidth();
            }
        });
        if (hide_from < items.length - 1) {
            items.eq(hide_from).nextAll('li.item').appendTo('ul.all-dropdown-menu>li>.container');
            $('.all-dropdown-menu ul.dropdown-inner li .dropdown-menu').addClass('overflow-h');

            //items.css({'width':(max_width / (hide_from + 1)) + 'px'});
            $('#menu .navbar-nav > li.all-category').show();
        }
        else
        {
            $('ul.navbar-nav div.dropdown-menu').removeClass('overflow-h');
            $('#menu .navbar-nav > li.all-category').hide();
        }
    }
    if(document.documentElement.clientWidth > 767 - 17) {
        $('li.all-category').mouseover(function () {
            $(this).addClass('open');
            //$('.all-dropdown-menu').show();
            $('#mob-menu').addClass('overflow-h');
        });
        $('li.all-category').mouseout(function () {
            $(this).removeClass('open');
            //$('.all-dropdown-menu').hide();
            $('#mob-menu').removeClass('overflow-h');
        });

        $(window).on('resize', function(){
            rMenu();
        }).trigger('resize');
    }
    $('.mob-dropdown .has-child').append('<span class="open-mob-dropdown"></span>');

    function openMob() {
        $('.open-mob-dropdown').click(function(){
            $(this).parents('.has-child').find('.sub_menu').slideToggle();
            $(this).toggleClass('minus');
            $(this).parents('.has-child').siblings('.item').find('.sub_menu').slideUp();
            $(this).parents('.has-child').siblings('.item').find('.open-mob-dropdown').removeClass('minus');
        });
    }
    openMob();
    // Resize --
    $(window).resize(function () {
        var curW = $(this).width();
        if (curW < (768 - 17)) {
            $('#mob-menu .nav').addClass('mob-dropdown');
            if( $('.mob-dropdown .has-child').find('span').hasClass('open-mob-dropdown')){
                $('.mob-dropdown .has-child').find('span').remove();
            }
            $('.mob-dropdown .has-child').append('<span class="open-mob-dropdown"></span>');
            $('.all-dropdown-menu').find('.item').appendTo('.nav.navbar-nav.mob-dropdown');
            openMob();
        } else{
            $('#mob-menu .nav').removeClass('mob-dropdown');
            rMenu();
            $('body').removeClass('overflow-h');
            $('.contain-swipe').removeClass('open-sidebar');
        }
    });

    $(function () {
        var curWid = $(this).width();
        if (curWid < (768 - 17)) {
            $('#mob-menu .nav').addClass('mob-dropdown');
            $('.mob-dropdown .has-child').append('<span class="open-mob-dropdown"></span>');
            openMob();

        }else{
            $('#mob-menu .nav').removeClass('mob-dropdown');
        }
    });
    // -- Resize


    /* MENU */

    // Product List
    $('#list-catalog').click(function() {
        $('#content .product-grid > .clearfix').remove();

        $('#content .row > .product-grid').attr('class', 'product-layout product-list col-xs-12');
        $('#grid-catalog').removeClass('active');
        $('#list-catalog').addClass('active');

        localStorage.setItem('display', 'list');
    });

    // Product Grid
    $('#grid-catalog').click(function() {
        // What a shame bootstrap does not take into account dynamically loaded columns
        var columns = $('#column-right, #column-left').length;

        if (columns == 2) {
            $('#content .product-list').attr('class', 'product-layout product-grid col-lg-6 col-md-12 col-sm-12 col-xs-6');
        } else if (columns == 1) {
            $('#content .product-list').attr('class', 'product-layout product-grid col-lg-4 col-md-6 col-sm-6 col-xs-6');
        } else {
            $('#content .product-list').attr('class', 'product-layout product-grid col-lg-3 col-md-4 col-sm-6 col-xs-6');
        }

        $('#list-catalog').removeClass('active');
        $('#grid-catalog').addClass('active');

        localStorage.setItem('display', 'grid');
    });

    if (localStorage.getItem('display') == 'list') {
        $('#list-catalog').trigger('click');
        $('#list-catalog').addClass('active');
    } else {
        $('#grid-catalog').trigger('click');
        $('#grid-catalog').addClass('active');
    }

    // Active menu
    $(function () {
        $('#menu a').each(function () {
            var location = window.location.href;
            var link = this.href;
            if(location == link) {
                $(this).addClass('active');
                $(this).parents('.sub_menu').siblings('.top-menu_link').addClass('active');
            }
        });
    });

    //scroll to top
    $(window).scroll(function () {
        if ($(this).scrollTop() > 150) $('.top-btn').fadeIn();
        else $('.top-btn').fadeOut();
    });
    $('.top-btn').click(function () {
        $('body, html').animate({
            scrollTop: 0
        }, 1000);
    });

    $('.map-slide').click(function(){
        $('.map-container').slideToggle();
        $('.map-hide').toggle();
        $('.map-show').toggle();
        $(this).toggleClass('active');
    });


    $(document).on('click touchstart', '.dropdown-menu', function (e) {
        $(this).hasClass('dop-menu-dropdown') && e.stopPropagation();
    });

});